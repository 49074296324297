import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Header from '../../components/header';
import { jefaturas } from './../../utils/servicios-medicos/constants';
import { useStyles } from '../../utils/servicios-medicos/styles';
import { useGlobalStyles } from '../../utils/styles';

const CEM = () => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();

  return (
    <Layout>
      <SEO title='CEM' />

      <div className={globalClasses.container}>
        <Header title={`Centro de especialidades médicas "Dr. Julián A. Manzur Ocaña" servicio médico`} />

        <div className={globalClasses.content}>
          <Typography>El Centro de Especialidades Médicas cuenta con las siguietnes jefaturas de servicios, además de los de alta especialidad:</Typography>

          <Grid container spacing={5} className={classes.jefaturas}>
            {jefaturas.map((jefatura, index) => (
              <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                <div className={classes.card}>
                  {jefatura.icon ? jefatura.icon : <img src={jefatura.image} width={60} />}
                  <Typography>{jefatura.name}</Typography>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
    </Layout>
  );
};

export default CEM;