import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { containerProps, contentProps } from "../../utils/constants";

export const useStyles = makeStyles((theme: Theme) => createStyles({
  info: {
    marginTop: theme.spacing(5)
  },
  table: {
    '& thead': {
      backgroundColor: theme.palette.primary.main,
      '& th': {
        color: '#fff',
      }
    }
  },
  jefaturas: {
    marginTop: theme.spacing(3)
  },
  card: {
    border: `1px solid ${grey[300]}`,
    borderRadius: 4,
    padding: theme.spacing(2),
    textAlign: 'center',
    transition: 'all .3s ease-in-out',
    '& p': {
      marginTop: theme.spacing(2)
    },
    '&:hover': {
      border: `1px solid ${theme.palette.primary.main}`
    }
  },
  tramite: {
    '& .MuiAvatar-root': {
      width: 33,
      height: 33,
      fontSize: 15,
      transition: 'all .4s ease'
    },
    '&:hover .MuiAvatar-root': {
      backgroundColor: theme.palette.primary.main
    }
  }
}));