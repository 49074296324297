import { mdiBaby, mdiBedEmpty, mdiBloodBag, mdiBrain, mdiEye, mdiFolderMarker, mdiFolderPlus, mdiHumanFemale, mdiMicroscope, mdiRadiologyBox, mdiTestTube } from "@mdi/js";
import Icon from '@mdi/react';
import React from 'react';
import { IAddressType, IJefatura } from "./interfaces";

export const addressTypes: IAddressType[] = [
  {
    title: "Carpa",
    descripcion: "El instituto cuenta actualmente con módulos de atención a pacientes con problemas de las vías respiratorias, las cuales están ubicadas en el siguiente domicilio.",
    columns: ["No.", "Carpa", "Domicilio"],
    rows: [
      {
        no: 1,
        title: "Centro Médico Juan Puig Palacios",
        subtitle: "",
        phone: '9343440397',
        description: "Av. Gregorio Méndez #2410, col. Atasta. CP 86100, Villahermosa, Tabasco.",
        email: "",
        extension: ""
      }
    ]
  },
  {
    title: "UMF",
    descripcion: "Así mismo, el instituto cuenta actualmente con 24 Unidades de Medicina Familiar en todo el estado, los cuales brindan el servicio de medicina familiar, odontología, curaciones y en los municipios de Cárdenas y Comalcalco, servicios de segundo nivel de atención. A continuación se proporcionan los domicilios de cada una de estas Unidades de Medicina Familiar:",
    columns: ["No.", "UMF", "Domicilio"],
    rows: [
      {
        no: 0,
        title: "DR. JORGE VALDEZ VÁZQUEZ",
        subtitle: "(COORDINADOR REGIÓN RÍOS)",
        phone: "9341023752",
        extension: "",
        description: "",
        email: "jorgevaldez@isset.gob.mx",
        rows: [
          {
            no: 1,
            title: "Balancán",
            subtitle: "",
            phone: "9343440397",
            description: "Calle Francisco Javier Mina #313, entre calles Arroyo San Marcos y Allende, col. El Palenque. CP 86930 Balancán, Tabasco.",
            email: "",
            extension: ""
          },
          {
            no: 2,
            title: "E. Zapata",
            subtitle: "",
            phone: "9343429045",
            description: "Calle Licenciado José María Pino Suárez, S/N, Ref 1: Calle Mariano Abasolo, Ref 2: Calle Gregorio Méndez Magaña, Ref 3: Calle Doctor Simón Sarlat, col. Centro . CP 86990, Emiliano Zapata, Tabasco.",
            email: "",
            extension: ""
          },
          {
            no: 3,
            title: "Unidad de Hemodiálisis E. Zapata",
            subtitle: "",
            phone: "",
            description: "Calle Dr. Simón Sarlat #75, entre 5 de Mayo a un costado de Oficinas de la Federación, col. Centro. CP 86990, Emiliano Zapata, Tabasco.",
            email: "",
            extension: ""
          },
          {
            no: 4,
            title: "Tenosique",
            subtitle: "",
            phone: "9343420853",
            description: "Calle 37 esquina calle 20, #207, col. Centro. CP 86900, Tenosique de Pino Suarez, Tabasco.",
            email: "",
            extension: ""
          },
          {
            no: 5,
            title: "Villa El Triunfo",
            subtitle: "",
            phone: "",
            description: "Calle Gustavo Díaz Ordaz #104, esquina Carlos A. Madrazo, en contra esquina de la escuela “Justo Sierra”, col. Las Flores. CP 86950, Villa El Triunfo, Balancán, Tabasco.",
            email: "",
            extension: ""
          },
          {
            no: 6,
            title: "Jonuta",
            subtitle: "",
            phone: "9133670141",
            description: "Calle Aquiles Serdán, S/N, esquina Álvaro Obregón frente a Pinturas “COMEX”, col. Centro. CP 86780, Jonuta, Tabasco.",
            email: "",
            extension: ""
          }
        ]
      },
      {
        no: 0,
        title: "DR. ROBERTO SANTIAGO NOVELO",
        subtitle: "(COORDINADOR REGIÓN CHONTALPA GRANDE)",
        description: "",
        phone: "",
        extension: "",
        email: "robertosantiago@isset.gob.mx",
        rows: [
          {
            no: 7,
            title: "Cárdenas",
            subtitle: "",
            phone: "9373721811",
            description: "Calle 27 de febrero, esquina con Avenida Abraham Bandala, col. Pueblo Nuevo. CP 86500, Cárdenas, Tabasco.",
            email: "",
            extension: ""
          },
          {
            no: 8,
            title: "Villa Sánchez Magallanes",
            subtitle: "",
            phone: "9232770659",
            description: "Calle Francisco I. Madero #34, esquina calle Lázaro Cárdenas, col. Centro. CP 86460, Villa Puerto Coronel Sánchez Magallanes, Cárdenas, Tabasco.",
            email: "",
            extension: ""
          },
          {
            no: 9,
            title: "Huimanguillo",
            subtitle: "",
            phone: "9173751694",
            description: "Calle Mariano Abasolo #46, esquina calle Nicolás Bravo, col. Centro. CP 86400, Huimanguillo, Tabasco.",
            email: "",
            extension: ""
          },
          {
            no: 10,
            title: "Villa La Venta",
            subtitle: "",
            phone: "9232320149",
            description: "Calle José Mercedes Gamas #20, entre Mora Ascanio y Zaragoza, col. Centro. CP 86418, Tabasco.",
            email: "",
            extension: ""
          }
        ]
      },
      {
        no: 0,
        title: "DRA. TERESA DEL ROCIO GUERRERO REYES ",
        subtitle: "(JEFA DEL CENTRO REGIONAL DE SEGURIDAD SOCIAL DE CENTLA)",
        description: "",
        phone: "9131045496",
        extension: "",
        email: "teresaguerrero@isset.gob.mx",
        rows: [
          {
            no: 11,
            title: "Centla",
            subtitle: "",
            phone: "9133320934",
            description: "Calle Coronel Gregorio Mendez Magaña, esquina Vicente Rivas Palacio, col. Deportiva. Centla, Tabasco.",
            email: "",
            extension: ""
          },
          {
            no: 12,
            title: "Villa Vicente Guerrero",
            subtitle: "",
            phone: "9133328376",
            description: "Calle Gregorio Mendez Magaña S/N, entre Francisco Villa y Corregidora, col. Centro. CP 86761, Villa Vicente Guerrero, Centla, Tabasco",
            email: "",
            extension: ""
          },
          {
            no: 13,
            title: "Villa Tamulte de las Sabanas",
            subtitle: "",
            phone: "9934125039",
            description: "Calle Porfirio Díaz, #66, Ejido José G. Asmitia. CP 86250, Villa Tamulté de las Sabanas, Villahermosa, Tabasco.",
            email: "",
            extension: ""
          },
          {
            no: 14,
            title: "Paraíso",
            subtitle: "",
            phone: "9333330507",
            description: "Calle Manuel R. Domínguez, col. La Ceiba, Paraíso, Tabasco",
            email: "",
            extension: ""
          }
        ]
      },
      {
        no: 0,
        title: "DRA. SARA LIMA RODRÍGUEZ",
        subtitle: "(COORDINADOR REGIÓN CHONTALPA CHICA)",
        description: "",
        phone: "",
        extension: "",
        email: "sarybelem.slrg@gmail.com",
        rows: [
          {
            no: 15,
            title: "Comalcalco",
            subtitle: "",
            phone: "9333340608",
            description: "Calle Granada S/N, Fraccionamiento Santo Domingo, A Lado De Soriana. CP 86340, Comalcalco, Tabasco.",
            email: "",
            extension: ""
          },
          {
            no: 16,
            title: "Cunduacán",
            subtitle: "",
            phone: "9143360126",
            description: "Calle Corregidora #93, Barrio Santiaguito, CP 86690, Cunduacán, Tabasco.",
            email: "",
            extension: ""
          },
          {
            no: 17,
            title: "Jalpa de Méndez",
            subtitle: "",
            phone: "9143370749",
            description: "Calle Corregidora Josefa Ortiz De Domínguez S/N, Barrio La Candelaria, col. Centro. CP 86200, Jalpa de Méndez, Tabasco.",
            email: "",
            extension: ""
          },
          {
            no: 18,
            title: "Nacajuca",
            subtitle: "",
            phone: "",
            description: "Calle Eusibio Castillo #125, Ref 1: a un lado de la jurisdicción sanitaria, Nacajuca, Tabasco.",
            email: "",
            extension: ""
          }
        ]
      },
      {
        no: 0,
        title: "DR. FRANCISCO JAVIER HERNÁNDEZ NAREZ",
        subtitle: "(COORDINADOR REGIÓN SIERRA)",
        description: "",
        phone: "9321120915",
        extension: "",
        email: "centroregionaljalapa@gmail.com",
        rows: [
          {
            no: 19,
            title: "Jalapa",
            subtitle: "",
            phone: "9323630185",
            description: "Prolongación de Pino Suarez S/N, a lado de la casa magisterial adelante del Cobatab Plantel 11, col. Centro. CP 86850 Jalapa, Tabasco.",
            email: "",
            extension: ""
          },
          {
            no: 20,
            title: "Macuspana",
            subtitle: "",
            phone: "9363621504",
            description: "Calle Primera Circunvalación #72, entre calle Vicente Guerrero y Reforma, Col. Centro. CP 86706, Macuspana, Tabasco.",
            email: "",
            extension: ""
          },
          {
            no: 21,
            title: "Villa Benito Juárez",
            subtitle: "",
            phone: "",
            description: "Calle Benito Juárez #64, esquina calle Circunvalación Norte, Ref 1: entre calle Fabian Chable y 5 de Ferbrero, col. Centro. CP 86728, Villa Benito Juárez, Macuspana, Tabasco.",
            email: "",
            extension: ""
          },
          {
            no: 22,
            title: "Teapa",
            subtitle: "",
            phone: "9323221401",
            description: "Simón Sarlat Nova #118, col. Centro. CP 86800, Teapa, Tabasco.",
            email: "",
            extension: ""
          },
          {
            no: 23,
            title: "Tacotalpa",
            subtitle: "",
            phone: "9323242555",
            description: "Calle Miguel Orrico de Llanos, S/N, a un Costado del Casino Municipal entre calles Adolfo Ruiz Cortines y 27 de Febrero, col. Nuevo Tacotalpa. CP 86870, Tacotalpa, Tabasco.",
            email: "",
            extension: ""
          },
          {
            no: 24,
            title: "Villa Tapijulapa",
            subtitle: "",
            phone: "",
            description: "Calles Carlos A. Madrazo y Francisco J. Santa María #9, entre calle 1 y calle 2, col. Centro. CP 86890, Villa Tapijulapa, Tacotalpa, Tabasco.",
            email: "",
            extension: ""
          }
        ]
      },
      {
        no: 0,
        title: "DR. ADRIÁN ROMUALDO ZAVALA HERRERA",
        subtitle: "(TITULAR DE LA UMF CENTRO)",
        description: "",
        phone: "9933582850",
        extension: "63153",
        email: "adrianzavala@isset.gob.mx",
        rows: [
          {
            no: 25,
            title: "Centro",
            subtitle: "",
            phone: "",
            description: "Av. Gregorio Méndez #2410, col. Atasta. CP 86100, Villahermosa, Tabasco.",
            email: "",
            extension: ""
          }
        ]
      }
    ]
  }
];

export const jefaturas: IJefatura[] = [
  {
    name: 'Consulta externa',
    icon: <Icon path={mdiFolderMarker} size={2.5} color='#000' />,
    image: ''
  },
  {
    name: 'Consulta interna',
    icon: <Icon path={mdiFolderPlus} size={2.5} color='#000' />,
    image: ''
  },
  {
    name: 'Ginecologia',
    icon: <Icon path={mdiHumanFemale} size={2.5} color='#000' />,
    image: ''
  },
  {
    name: 'Oftalmologia',
    icon: <Icon path={mdiEye} size={2.5} color='#000' />,
    image: ''
  },
  {
    name: 'Epidemiología',
    icon: null,
    image: 'https://img.icons8.com/ios-glyphs/90/000000/virus.png'
  },
  {
    name: 'Anestesiología',
    icon: <Icon path={mdiBedEmpty} size={2.5} color='#000' />,
    image: ''
  },
  {
    name: 'Cirugía',
    icon: null,
    image: 'https://img.icons8.com/ios-filled/100/000000/scalpel.png'
  },
  {
    name: 'Traumatología',
    icon: null,
    image: 'https://img.icons8.com/ios-glyphs/90/000000/broken-bone.png'
  },
  {
    name: 'Nefrología',
    icon: null,
    image: 'https://img.icons8.com/ios-filled/100/000000/kidney.png'
  },
  {
    name: 'Laboratorio',
    icon: <Icon path={mdiTestTube} size={2.5} color='#000' />,
    image: ''
  },
  {
    name: 'Urgencias',
    icon: null,
    image: 'https://img.icons8.com/ios-filled/100/000000/hospital-wagon-without-a-siren.png'
  },
  {
    name: 'Neurología',
    icon: <Icon path={mdiBrain} size={2.5} color='#000' />,
    image: ''
  },
  {
    name: 'Pediatría',
    icon: <Icon path={mdiBaby} size={2.5} color='#000' />,
    image: ''
  },
  {
    name: 'Imagenología',
    icon: <Icon path={mdiRadiologyBox} size={2.5} color='#000' />,
    image: ''
  },
  {
    name: 'Patología',
    icon: <Icon path={mdiMicroscope} size={2.5} color='#000' />,
    image: ''
  },
  {
    name: 'Banco de sangre',
    icon: <Icon path={mdiBloodBag} size={2.5} color='#000' />,
    image: ''
  }
];